const initialState = {
  locale: 'zh-TW',
  isMenuCollapsed: true, // true開 false關
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_MENU_COLLAPSED': {
      state.isMenuCollapsed = action.payload
      return { ...state }
    }

    default:
      return state
  }
}
