import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { animated, useTransition, useSpring } from 'react-spring'
import useScreenOrientation from 'react-hook-screen-orientation'
import useMedia from 'use-media'

import {
  ModalBlock,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalDivider,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from './styles.js'
import * as S from './styles'

const Modal = ({
  title,
  style,
  header,
  footer,
  darker,
  children,
  active,
  width,
  height,
  maxWidth,
  hideModal,
  maxHeight,
  isClosable,
  closeIcon,
}) => {
  const parentDiv = useRef(null)
  const transitions = useTransition(active, {
    from: { opacity: 0, zIndex: 1000 },
    enter: { opacity: 1, zIndex: 1000 },
    leave: { opacity: 0 },
  })

  const styles = useSpring({
    transform: active ? 'translateY(0px)' : 'translateY(100px)',
  })

  useEffect(() => {
    if (active && parentDiv.current) {
      parentDiv.current.focus()
    }
  }, [active])

  const [screenHorizon, setScreenHorizon] = useState(false)
  const horizonForIOS = useMedia('(max-height: 450px)')

  const screenOrientation = useScreenOrientation()
  useEffect(() => {
    // console.log('screenHorizon: ', screenOrientation)
    // console.log('horizonForIOS: ', horizonForIOS.toString())
    if (!screenOrientation) {
      setScreenHorizon(horizonForIOS)
    } else if (screenOrientation.includes('landscape')) {
      setScreenHorizon(true)
    } else {
      setScreenHorizon(false)
    }
  }, [screenOrientation, horizonForIOS])

  return (
    <Fragment>
      {transitions(
        (props, item) =>
          item && (
            <ModalBlock screenHorizon={screenHorizon}>
              <animated.div
                style={{
                  ...props,
                }}
              >
                {active && (
                  <>
                    <ModalOverlay darker={darker} onClick={(e) => (isClosable ? hideModal() : e.preventDefault())}></ModalOverlay>
                    <animated.div
                      style={{
                        ...styles,
                      }}
                    >
                      <ModalContainer width={width} height={height} maxWidth={maxWidth} maxHeight={maxHeight} screenHorizon={screenHorizon}>
                        {header && (
                          <>
                            <ModalHeader>
                              {title && <ModalTitle>{title}</ModalTitle>}
                              {title && <ModalDivider />}
                            </ModalHeader>
                            {closeIcon && (
                              <div className="position-absolute">
                                <ModalClose screenHorizon={screenHorizon} onClick={() => hideModal()}>
                                  <img src="/resources/images/x.png" />
                                </ModalClose>
                              </div>
                            )}
                          </>
                        )}
                        <ModalBody height={height}>{children}</ModalBody>
                        {footer && <ModalFooter>{footer}</ModalFooter>}
                      </ModalContainer>
                    </animated.div>
                  </>
                )}
              </animated.div>
            </ModalBlock>
          ),
      )}
    </Fragment>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  style: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  active: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  isClosable: PropTypes.bool,
  closeIcon: PropTypes.bool,
  header: PropTypes.bool,
  darker: PropTypes.bool,
}

Modal.defaultProps = {
  hideModal: null,
  closeIcon: true,
  isClosable: true,
  width: null,
  header: true,
  darker: false,
}

export default Modal
