import { intervalToDuration } from 'date-fns'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'

/**
 *
 * @param {file} file
 * @returns {Base64} 回傳base64
 */
export async function getBase64(file) {
  const data = await loadBase64(file)

  //切割base64圖片內容
  const result = data.split(',')

  return result[1]

  //轉成base64
  function loadBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = function (error) {
        reject(error)
        toast.error('上傳失敗，請重試')
      }
    })
  }
}

/**
 *
 * @description 將blob轉成base64
 * @param {blob} blob blob
 * @returns {Base64} 回傳base64
 */
export async function blobToBase64(blob) {
  const data = await loadArraybuffer(blob)

  //切割base64圖片內容
  const result = data.split(',')

  return result[1]

  function loadArraybuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
}

/**
 *
 * @param {number} num
 * @returns {number} 四捨五入至小數點末兩位
 */
export function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}

/**
 *
 * @param {file} file
 * @returns {ArrayBuffer} 回傳buffer array []
 */
export async function getArrayBuffer(file) {
  const data = await loadArraybuffer(file)
  const uint8View = new Uint8Array(data)
  const arr = Array.from(uint8View)
  return uint8View

  function loadArraybuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => {
        reject(error)
        toast.error('上傳失敗，請重試')
      }
    })
  }
}

/**
 *
 * @description 將img tag轉成Uint8Array
 * @param {element} img img element
 * @returns {ArrayBuffer} Uint8Array
 */
export async function urlToArrayBuffer(img) {
  var canvas = document.createElement('canvas')
  var context = canvas.getContext('2d')
  img.setAttribute('crossorigin', 'anonymous')
  canvas.height = img.naturalHeight
  canvas.width = img.naturalWidth
  await context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight)
  var base64String = canvas.toDataURL()
  var base64 = base64String.substring(base64String.indexOf(',') + 1)
  const byteArray = await base64ToArrayBuffer(base64)
  return byteArray
}

/**
 *
 * @description 將img url轉成Uint8Array
 * @param {string} url 圖片網址
 * @returns {ArrayBuffer} Uint8Array
 */
export const getArrayBufferFromUrl = async (url) => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = async () => {
      const base64data = reader.result
      var base64 = base64data.substring(base64data.indexOf(',') + 1)
      const arrayBufferData = await base64ToArrayBuffer(base64)
      const uint8View = new Uint8Array(arrayBufferData)
      resolve(uint8View)
    }
  })
}

/**
 *
 * @description 將img url轉成base64
 * @param {string} url 圖片網址
 * @returns {Base64} base64
 */
export const getBase64FromUrl = async (url) => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = async () => {
      const base64data = reader.result
      var base64 = base64data.substring(base64data.indexOf(',') + 1)
      resolve(base64)
    }
  })
}

/**
 *
 * @description 將base64轉成Uint8Array
 * @param {base64} base64 base64
 * @returns {ArrayBuffer} Uint8Array
 */
export async function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

/**
 *
 * @description 將blob轉成Uint8Array
 * @param {blob} blob blob
 * @returns {ArrayBuffer} Uint8Array
 */
export async function blobToArrayBuffer(blob) {
  const data = await loadArraybuffer(blob)
  const uint8View = new Uint8Array(data)
  return uint8View

  function loadArraybuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
}

/**
 *
 * @description 傳入檔案回傳影片時長
 * @param {*} file 檔案
 * @returns {number} 影片時長（秒）
 */
export async function getDuration(file) {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = function () {
      resolve(parseInt(video.duration, 10))
    }
    video.src = URL.createObjectURL(file)
  })
}

/**
 *
 * @param {string} url 影片連結
 * @param {number} seekTo 要取得縮圖的時間（秒）
 * @returns {blob} 縮圖的blob
 */
export function getVideoCover(url, seekTo = 0.0) {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video')
    videoPlayer.setAttribute('src', url)
    videoPlayer.load()
    videoPlayer.addEventListener('error', (ex) => {
      reject('error when loading video file', ex)
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.')
        return
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo
      }, 200)
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // console.log('video is now paused at %ss.', seekTo)
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas')
        canvas.width = videoPlayer.videoWidth
        canvas.height = videoPlayer.videoHeight
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d')
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob)
          },
          'image/jpeg',
          0.75 /* quality */,
        )
      })
    })
  })
}

/**
 *
 * @param {number} min 最小值
 * @param {number} max 最大值
 * @returns {number} 隨機亂數
 */
export function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 *
 * @param {string} title 標題
 * @param {Node} content 內文
 * @param func 確認函數
 */
export function showConfirm(title, content, func) {
  const dispatch = useDispatch()

  dispatch({
    type: 'CONFIRM_SHOW',
    payload: {
      title: title,
      content: content,
      func: func,
    },
  })
}

export function formateTimeToHMS(time) {
  const duration = intervalToDuration({ start: 0, end: time * 1000 })
  if (duration.hours && duration.hours.toString().length !== 2) duration.hours = '0' + duration.hours
  if (duration.minutes && duration.minutes.toString().length !== 2) duration.minutes = '0' + duration.minutes
  if (duration.seconds && duration.seconds.toString().length !== 2) duration.seconds = '0' + duration.seconds
  const formattedTime = `${duration?.hours || '00'}:${duration?.minutes || '00'}:${duration?.seconds || '00'}`
  return formattedTime
}

export function countdownFunc(date) {
  // var countDownDate = new Date(date).getTime()

  // // Update the count down every 1 second
  // var x = setInterval(function () {
  //   // Get today's date and time
  //   var now = new Date().getTime()

  //   // Find the distance between now and the count down date
  //   var distance = countDownDate - now

  //   // Time calculations for days, hours, minutes and seconds
  //   var days = Math.floor(distance / (1000 * 60 * 60 * 24))
  //   var hours = Math.floor(
  //     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  //   )
  //   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  //   var seconds = Math.floor((distance % (1000 * 60)) / 1000)

  //   // Display the result in the element with id="demo"
  //   return (
  //     <div>
  //       {days}:{hours}:{minutes}:{seconds}
  //     </div>
  //   )
  // }, 1000)
  var now = new Date().getTime()
  intervalToDuration({
    start: new Date(now),
    end: new Date(date),
  })
}

/**
 *
 * @description 驗證電話是否正確或存在
 * @param {string} 電話號碼
 * @returns {boolean} 是否正確
 */
export function isValidPhone(countryCode, phone) {
  const regex = /^[0-9]+$/
  const phonex = regex.test(phone)
  if (phonex === false) {
    return false
  }
  return isValidPhoneNumber('+' + countryCode + phone)
}
