import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        margin: 0;
        box-sizing: border-box;
    }
  
    ::selection {
    background: ${({ theme }) => theme.bg4};
    }

    img {
    user-select: none;
    user-drag: none;
    }

    body {
        background: ${({ theme }) => theme.bg2};
        color: ${({ theme }) => theme.text};
        font-family: 'Noto Sans TC', sans-serif;
        letter-spacing: .6px;
        ::-webkit-scrollbar {
            display: none;
        }
   
    }

    *:disabled {
       input{
        background: #ccc;
       }
       textarea{
        background: #ccc;
       }
       button{
        background: #ccc;
       }
       select{
        background: #ccc;
       }
        .rdw-editor-main{
        background: #ccc;
        pointer-events: none;
       }
    }


`
