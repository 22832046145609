// const initialState = {
//   videoList: [],
//   filterVideoList: [],
// }

// export default function videoReducer(state = initialState, action) {
//   switch (action.type) {
//     case 'VIDEO_SET_VIDEO_LIST': {
//       state.videoList = [...action.payload]
//       state.filterVideoList = [...action.payload]
//       return { ...state }
//     }

//     case 'VIDEO_FILTER': {
//       if (action.payload === '') {
//         state.filterVideoList = state.videoList
//         return { ...state }
//       }
//       const filtered = state.videoList.filter((e) => e.videoName.toLowerCase().includes(action.payload.toLowerCase()))
//       state.filterVideoList = [...filtered]
//       return { ...state }
//     }

//     default:
//       return { ...state }
//   }
// }
const initialState = []

export default function videoReducer(state = initialState, action) {
  switch (action.type) {
    case 'VIDEO_SET_VIDEO_LIST': {
      state = action.payload
      return [...state]
    }

    default:
      return [...state]
  }
}
