import React, { PureComponent } from 'react'
// import { Spring } from 'react-spring'
import * as S from './styles'

class Loading extends PureComponent {
  render() {
    return (
      <S.Loading className=" justify-content-center">
        <div className="spinner-border m-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </S.Loading>
    )
  }
}

export default Loading
