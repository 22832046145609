import styled from 'styled-components'
import { v } from 'styles/variables.js'

export const Tag = styled.div`
  border-radius: 50px;
  font-size: 12px;
  margin: 2px;
  font-weight: 900;
  padding: 2px 10px;
  cursor: pointer;

  width: auto;
  display: inline-block !important;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme, color }) =>
    color === 'green'
      ? theme.success
      : color === 'red'
      ? theme.danger
      : color === 'purple'
      ? theme.purple
      : color === 'yellow'
      ? theme.primaryYellow
      : color === 'blue'
      ? theme.primaryBlue
      : theme.grey};
`
