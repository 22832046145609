const initialState = []

export default function blocklistReducer(state = initialState, action) {
  switch (action.type) {
    case 'FIRM_BLOCKLIST_LOAD': {
      return [...action.payload]
    }

    default:
      return state
  }
}
