const initialState = {
  file: null,
  chunkCount: 0,
  beginingOfTheChunk: 0,
  endOfTheChunk: 0,
  counter: 1,
  offset: 0,
  fileName: '',
  fileSize: 0,
  fileGuid: '',
  progress: 0,
  source: '',
  duration: 0,
  errors: 0,
  thumbnail: [],
}

export default function uploadReducer(state = initialState, action) {
  switch (action.type) {
    case 'VIDEO_START_UPLOAD': {
      state = action.payload
      return { ...state }
    }

    case 'VIDEO_ADD_COUNTER': {
      state.counter += 1
      return { ...state }
    }

    case 'VIDEO_ADD_OFFSET': {
      state.offset += action.payload
      return { ...state }
    }

    case 'VIDEO_UPLOADING_VIDEO': {
      state.progress = action.payload
      return { ...state }
    }

    case 'VIDEO_SET_BEGINING_CHUNK': {
      state.beginingOfTheChunk = action.payload
      return { ...state }
    }

    case 'VIDEO_SET_END_CHUNK': {
      state.endOfTheChunk = action.payload
      return { ...state }
    }

    case 'VIDEO_SET_FILE_NAME': {
      state.fileName = action.payload
      return { ...state }
    }

    case 'VIDEO_UPLOADING_ADD_ERROR_COUNT': {
      state.errors += 1
      return { ...state }
    }

    case 'VIDEO_UPLOADING_RESET_ERROR_COUNT': {
      state.errors = 0
      return { ...state }
    }

    case 'VIDEO_UPLOAD_RESTART': {
      state.chunkCount = 0
      state.beginingOfTheChunk = 0
      state.endOfTheChunk = 0
      state.counter = 0
      state.offset = 0
      state.progress = 0
      state.errors = 0
      return { ...state }
    }

    case 'VIDEO_RESET': {
      state = initialState
      state.thumbnail = []
      return { ...state }
    }

    default:
      return { ...state }
  }
}
