const initialState = {}
// {
//   name: null,
//   userImgId: null,
//   userImgUrl: null,
// }

export default function chatUserReducer(state = initialState, action) {
  switch (action.type) {
    case 'CHAT_USER_SET_IMG': {
      // console.log(action.payload.userImgUrl)
      state.userImgId = action.payload.userImgId
      state.userImgUrl = action.payload.userImgUrl
      return { ...state }
    }
    case 'CHAT_USER_SET_NAME': {
      state.name = action.payload
      return { ...state }
    }

    case 'CHAT_USER_SET_USERDATA': {
      state.realName = action.payload.formName
      state.phone = action.payload.formNumber
      state.email = action.payload.formEmail
      return { ...state }
    }

    default: {
      return state
    }
  }
}
