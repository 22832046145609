import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

const DropDown = ({ icon, options }) => {
  const [isOpen, setIsOpen] = useState(false)

  function handleClickItem() {
    setIsOpen(false)
  }

  return (
    <S.Main>
      <S.DropDownContainer>
        <S.DropDownHeader
          onBlur={() =>
            setTimeout(() => {
              setIsOpen(false)
            }, 300)
          }
        >
          <S.MoreBtn onClick={() => setIsOpen(true)}>{icon}</S.MoreBtn>
        </S.DropDownHeader>
        {isOpen && (
          <S.DropDownListContainer onBlur={() => setIsOpen(false)}>
            <S.DropDownList>
              {options.map((option, index) => (
                <S.ListItem key={index} onClick={handleClickItem}>
                  {option}
                </S.ListItem>
              ))}
            </S.DropDownList>
          </S.DropDownListContainer>
        )}
      </S.DropDownContainer>
    </S.Main>
  )
}

DropDown.propTypes = {
  icon: PropTypes.node,
  options: PropTypes.array,
}

export default DropDown
