const initialState = []

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case 'CHAT_RESET': {
      state = []
      return [...state]
    }

    case 'CHAT_LOAD_MESSAGE_HISTORY': {
      // console.log(action.payload)
      return action.payload
    }

    case 'CHAT_LOAD_MORE_MESSAGE_HISTORY': {
      state.splice(action.index, 1)
      return [...action.payload, ...state]
    }

    case 'CHAT_GOT_MESSAGE': {
      // if (action.payload.commentText.includes('http')) {
      //   action.payload.commentText = urlDistinguish(action.payload.commentText)
      // }
      return [...state, action.payload]
    }

    case 'CHAT_FAVORITE': {
      state[action.payload].favorite = !state[action.payload].favorite
      return [...state]
    }

    case 'CHAT_MUTE': {
      const userId = action.payload
      const muteUserHistory = state.reduce((map, current) => {
        if (current?.user?.userId === userId) {
          current.mute = true
          return [...map, current]
        }
        return [...map, current]
      }, [])

      return [...muteUserHistory]
    }

    case 'CHAT_MUTE_CANCEL': {
      const userId = action.payload
      const muteUserHistory = state.reduce((map, current) => {
        if (current?.user?.userId === userId) {
          current.mute = false
          return [...map, current]
        }
        return [...map, current]
      }, [])

      return [...muteUserHistory]
    }

    case 'CHAT_MUTE_BLOCK': {
      const userId = action.payload
      const muteUserHistory = state.reduce((map, current) => {
        if (current?.user?.userId === userId) {
          current.mute = true
          return [...map, current]
        }
        return [...map, current]
      }, [])

      return [...muteUserHistory]
    }

    default:
      return state
  }
}
