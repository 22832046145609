import toast from 'react-hot-toast'
import { teddyApi } from './base.ts'

export const firmLogin = async (req) => {
  const { data, headers } = await teddyApi.firmLogin.cutrackFirmLogin(req)
  return { data, headers }
}

export const firmAutoLogin = async (req) => {
  const { data, headers } = await teddyApi.firmAutoLogin.cutrackFirmAutoLogin(req)
  return { data, headers }
}

export const firmRegister = async (req) => {
  const { data } = await teddyApi.firmRegister.cutrackFirmRegister(req)
  return data
}

export const firmForgetPassword = async (account, email) => {
  const { data } = await teddyApi.sendForgotPassLetter.cutrackSendForgotPassLetter({ account: account, email: email })
  return data
}

export const firmUpdateForgotPass = async (token, password) => {
  const { data } = await teddyApi.updateForgotPass.cutrackUpdateForgotPass({ token: token, password: password })
  return data
}
