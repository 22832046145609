const initialState = {
  productList: [],
  filterProductList: [],
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case 'PRODUCT_SET_PRODUCT_LIST': {
      state.productList = action.payload
      state.filterProductList = action.payload
      return { ...state }
    }

    case 'PRODUCT_FILTER': {
      if (action.payload === '') {
        state.filterProductList = state.productList
        return { ...state }
      }
      const filtered = state.productList.filter((e) =>
        e.productName.toLowerCase().includes(action.payload.toLowerCase()),
      )
      state.filterProductList = [...filtered]
      return { ...state }
    }

    default:
      return { ...state }
  }
}
