const initialState = {
  liveWatchTimes: null,
  liveSignupTimes: null,
  associationSignupTimes: null,
  liveJoinTimes: null,
  associationJoinTimes: null,
  concurrentOnlineChart: null,
  commentPeriodChart: null,
  associationRecommendChartList: null,
  salesReportChart: null,
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case 'DASHBOARD_SET_DATA': {
      // console.log(action.payload)
      return { ...action.payload }
    }
    default: {
      return state
    }
  }
}
