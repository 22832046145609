import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RiMailFill, RiTimerFill } from 'react-icons/ri'
import * as S from './styles.js'

const Timer = () => {
  const [showSms, setShowSms] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const liveHours = useSelector((state) => state.user.firmInfo.liveHours)
  const { pointSafetyStockNotice, smsPoint, isSurenotify } = useSelector((state) => state.user.firmInfo)

  const showSmsWarn = () => {
    if (pointSafetyStockNotice) {
      return ' #您的簡訊點數過低！建議您盡快儲值。'
    } else {
      return ''
    }
  }
  const showTimeWarn = () => {
    if (liveHours) {
      const calc = liveHours?.split(':')
      const warning = parseInt(calc[0])
      if (warning <= 100) {
        return ' #您的直播時數低於100小時！'
      }
      if (warning <= 50) {
        return ' #您的直播時數低於50小時！'
      }
      if (warning == 0) {
        return ' #您的直播時數低於1小時！'
      }
    }
    return ''
  }

  const parseLiveHours = () => {
    if (liveHours) {
      const calc = liveHours?.split(':')

      return `${calc[0]}小時${calc[1]}分${calc[2]}秒`
    }
    return `---`
  }

  return (
    <S.Container>
      <S.ToggleButton showWarn={!showTimeWarn()} onClick={() => setShowTime(!showTime)}>
        <RiTimerFill />
        {showTime && (
          <S.Span>
            剩餘直播時數：<span>{parseLiveHours()}</span> <S.Alert>&nbsp;{showTimeWarn()}</S.Alert>
          </S.Span>
        )}
      </S.ToggleButton>
      {!isSurenotify && (
        <S.ToggleButton showWarn={!showSmsWarn()} onClick={() => setShowSms(!showSms)}>
          <RiMailFill />
          {showSms && (
            <S.Span>
              剩餘簡訊點數：<span>{smsPoint}</span> <S.Alert>&nbsp;{showSmsWarn()}</S.Alert>
            </S.Span>
          )}
        </S.ToggleButton>
      )}
    </S.Container>
  )
}

export default Timer
