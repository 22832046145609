/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export var ContentType
;(function (ContentType) {
  ContentType['Json'] = 'application/json'
  ContentType['FormData'] = 'multipart/form-data'
  ContentType['UrlEncoded'] = 'application/x-www-form-urlencoded'
  ContentType['Text'] = 'text/plain'
})(ContentType || (ContentType = {}))
export class HttpClient {
  baseUrl = ''
  securityData = null
  securityWorker
  abortControllers = new Map()
  customFetch = (...fetchParams) => fetch(...fetchParams)
  baseApiParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
  constructor(apiConfig = {}) {
    Object.assign(this, apiConfig)
  }
  setSecurityData = (data) => {
    this.securityData = data
  }
  encodeQueryParam(key, value) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }
  addQueryParam(query, key) {
    return this.encodeQueryParam(key, query[key])
  }
  addArrayQueryParam(query, key) {
    const value = query[key]
    return value.map((v) => this.encodeQueryParam(key, v)).join('&')
  }
  toQueryString(rawQuery) {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&')
  }
  addQueryParams(rawQuery) {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }
  contentFormatters = {
    [ContentType.Json]: (input) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input) => this.toQueryString(input),
  }
  mergeRequestParams(params1, params2) {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }
  createAbortSignal = (cancelToken) => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }
    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }
  abortRequest = (cancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)
    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }
  request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format
    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response
      r.data = null
      r.error = null
      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })
      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }
      if (!response.ok) throw data
      return data
    })
  }
}
/**
 * @title cutrack.proto
 * @version version not set
 */
export class Api extends HttpClient {
  block = {
    /**
 * No description
 *
 * @tags live
 * @name LiveBlockList
 * @summary 黑名單管理
載入黑名單列表
 * @request GET:/block
 */
    liveBlockList: (query, params = {}) =>
      this.request({
        path: `/block`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCancelBlock
     * @summary 解除黑名單
     * @request PUT:/block/{blockId}/cancel_block
     */
    liveCancelBlock: (blockId, body, params = {}) =>
      this.request({
        path: `/block/${blockId}/cancel_block`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCancelPermanentBlock
     * @summary 解除永久封禁
     * @request PUT:/block/{blockId}/cancel_permanent_block
     */
    liveCancelPermanentBlock: (blockId, body, params = {}) =>
      this.request({
        path: `/block/${blockId}/cancel_permanent_block`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LivePermanentBlock
     * @summary 永久封禁使用者
     * @request PUT:/block/{blockId}/permanent_block
     */
    livePermanentBlock: (blockId, body, params = {}) =>
      this.request({
        path: `/block/${blockId}/permanent_block`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  checkCouponStatus = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackCheckCouponStatus
     * @summary 確認優惠券是否可使用
     * @request GET:/check_coupon_status
     */
    cutrackCheckCouponStatus: (query, params = {}) =>
      this.request({
        path: `/check_coupon_status`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  commentMod = {
    /**
 * No description
 *
 * @tags live
 * @name LiveCommentModList
 * @summary 留言組管理
留言組列表
 * @request GET:/comment_mod
 */
    liveCommentModList: (query, params = {}) =>
      this.request({
        path: `/comment_mod`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCreateCommentMod
     * @summary 建立留言組
     * @request POST:/comment_mod
     */
    liveCreateCommentMod: (body, params = {}) =>
      this.request({
        path: `/comment_mod`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveLoadOneCommentMod
     * @summary 獲取單一留言組內容
     * @request GET:/comment_mod/{commentModId}
     */
    liveLoadOneCommentMod: (commentModId, params = {}) =>
      this.request({
        path: `/comment_mod/${commentModId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveDeleteCommentMod
     * @summary 刪除留言組
     * @request DELETE:/comment_mod/{commentModId}
     */
    liveDeleteCommentMod: (commentModId, body, params = {}) =>
      this.request({
        path: `/comment_mod/${commentModId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateCommentMod
     * @summary 編輯留言組
     * @request PUT:/comment_mod/{commentModId}
     */
    liveUpdateCommentMod: (commentModId, body, params = {}) =>
      this.request({
        path: `/comment_mod/${commentModId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveAddMarkCommentToCommentMod
     * @summary 直播標記留言添加進留言組
     * @request POST:/comment_mod/{commentModId}/add_mark_comment_to_comment_mod
     */
    liveAddMarkCommentToCommentMod: (commentModId, body, params = {}) =>
      this.request({
        path: `/comment_mod/${commentModId}/add_mark_comment_to_comment_mod`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCopyCommentMod
     * @summary 複製留言組
     * @request POST:/comment_mod/{commentModId}/copy_comment_mod
     */
    liveCopyCommentMod: (commentModId, body, params = {}) =>
      this.request({
        path: `/comment_mod/${commentModId}/copy_comment_mod`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  dashboard = {
    /**
 * No description
 *
 * @tags live
 * @name LiveLoadLiveDashboard
 * @summary 系統分析
載入直播分析圖表
 * @request GET:/dashboard
 */
    liveLoadLiveDashboard: (query, params = {}) =>
      this.request({
        path: `/dashboard`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveLoadFirmLiveHours
     * @summary 獲取直播時數
     * @request GET:/dashboard/load_firm_live_hours
     */
    liveLoadFirmLiveHours: (query, params = {}) =>
      this.request({
        path: `/dashboard/load_firm_live_hours`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  firmAutoLogin = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackFirmAutoLogin
     * @summary 廠商自動登入
     * @request POST:/firm_auto_login
     */
    cutrackFirmAutoLogin: (body, params = {}) =>
      this.request({
        path: `/firm_auto_login`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  firmInfo = {
    /**
     * No description
     *
     * @tags setting
     * @name SettingLoadFirmInfo
     * @summary 事件追蹤_載入廠商資料
     * @request GET:/firm_info/{firmId}
     */
    settingLoadFirmInfo: (firmId, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingUpdateFirmInfo
     * @summary 事件追蹤_編輯廠商資料
     * @request PUT:/firm_info/{firmId}
     */
    settingUpdateFirmInfo: (firmId, body, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingDisableSurenotify
     * @summary 關閉電子豹功能
     * @request PUT:/firm_info/{firmId}/disable_surenotify
     */
    settingDisableSurenotify: (firmId, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}/disable_surenotify`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingEnableSurenotify
     * @summary 開啟電子豹功能
     * @request PUT:/firm_info/{firmId}/enable_surenotify
     */
    settingEnableSurenotify: (firmId, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}/enable_surenotify`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingLoadFirmLiveHoursLog
     * @summary 幫賣直播時數儲值紀錄
     * @request GET:/firm_info/{firmId}/load_firm_live_hours_log
     */
    settingLoadFirmLiveHoursLog: (firmId, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}/load_firm_live_hours_log`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingLoadFirmPointLog
     * @summary 幫賣點數儲值紀錄
     * @request GET:/firm_info/{firmId}/load_firm_point_log
     */
    settingLoadFirmPointLog: (firmId, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}/load_firm_point_log`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingLoadFirmTopUpInfo
     * @summary 獲取儲值資訊
     * @request GET:/firm_info/{firmId}/load_firm_top_up_info
     */
    settingLoadFirmTopUpInfo: (firmId, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}/load_firm_top_up_info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags setting
     * @name SettingUpdateFirmPassword
     * @summary 編輯廠商密碼
     * @request PUT:/firm_info/{firmId}/update_firm_password
     */
    settingUpdateFirmPassword: (firmId, body, params = {}) =>
      this.request({
        path: `/firm_info/${firmId}/update_firm_password`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  firmLogin = {
    /**
 * No description
 *
 * @tags Cutrack
 * @name CutrackFirmLogin
 * @summary 登入
廠商登入
 * @request POST:/firm_login
 */
    cutrackFirmLogin: (body, params = {}) =>
      this.request({
        path: `/firm_login`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  firmRegister = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackFirmRegister
     * @summary 廠商註冊
     * @request POST:/firm_register
     */
    cutrackFirmRegister: (body, params = {}) =>
      this.request({
        path: `/firm_register`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  firmSignOut = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackFirmSignOut
     * @summary 廠商登出
     * @request DELETE:/firm_sign_out
     */
    cutrackFirmSignOut: (body, params = {}) =>
      this.request({
        path: `/firm_sign_out`,
        method: 'DELETE',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  letter = {
    /**
     * No description
     *
     * @tags signup
     * @name SignupLetterLogList
     * @summary 信件紀錄列表
     * @request GET:/letter
     */
    signupLetterLogList: (query, params = {}) =>
      this.request({
        path: `/letter`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
 * No description
 *
 * @tags signup
 * @name SignupSendLetter
 * @summary 簡訊信箱
寄送信件
 * @request POST:/letter
 */
    signupSendLetter: (body, params = {}) =>
      this.request({
        path: `/letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupDisableWebhook
     * @summary 關閉Webhook功能
     * @request PUT:/letter/disable_webhook
     */
    signupDisableWebhook: (body, params = {}) =>
      this.request({
        path: `/letter/disable_webhook`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupEnableWebhook
     * @summary 開啟Webhook功能
     * @request PUT:/letter/enable_webhook
     */
    signupEnableWebhook: (body, params = {}) =>
      this.request({
        path: `/letter/enable_webhook`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLiveBeginNoticeLetter
     * @summary 直播開始前 寄送通知信
     * @request POST:/letter/live_begin_notice_letter
     */
    signupLiveBeginNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/letter/live_begin_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLiveNoticeLetter
     * @summary 直播通知信寄出
     * @request POST:/letter/live_notice_letter
     */
    signupLiveNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/letter/live_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLoadEmailPreviewData
     * @summary 獲取Email預覽畫面資料
     * @request GET:/letter/load_email_preview_data
     */
    signupLoadEmailPreviewData: (query, params = {}) =>
      this.request({
        path: `/letter/load_email_preview_data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupOrderNoticeLetter
     * @summary 購買完成 寄送通知信
     * @request POST:/letter/order_notice_letter
     */
    signupOrderNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/letter/order_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupPaidNoticeLetter
     * @summary 結帳後 通知信寄出
     * @request POST:/letter/paid_notice_letter
     */
    signupPaidNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/letter/paid_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupSignupCompleteNoticeLetter
     * @summary 完成報名 寄送通知信
     * @request POST:/letter/signup_complete_notice_letter
     */
    signupSignupCompleteNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/letter/signup_complete_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupSignupNoticeLetter
     * @summary 報名完成通知信寄出
     * @request POST:/letter/signup_notice_letter
     */
    signupSignupNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/letter/signup_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLoadOneLetterLog
     * @summary 載入單一信件紀錄
     * @request GET:/letter/{surenotifyInfoId}
     */
    signupLoadOneLetterLog: (surenotifyInfoId, params = {}) =>
      this.request({
        path: `/letter/${surenotifyInfoId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupDeleteLetterLog
     * @summary 刪除信件紀錄
     * @request DELETE:/letter/{surenotifyInfoId}
     */
    signupDeleteLetterLog: (surenotifyInfoId, body, params = {}) =>
      this.request({
        path: `/letter/${surenotifyInfoId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  letterTemplate = {
    /**
     * No description
     *
     * @tags signup
     * @name SignupLetterTemplateList
     * @summary 信件模板列表
     * @request GET:/letter_template
     */
    signupLetterTemplateList: (query, params = {}) =>
      this.request({
        path: `/letter_template`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
 * No description
 *
 * @tags signup
 * @name SignupCreateLetterTemplate
 * @summary 信件模板管理
建立信件模板
 * @request POST:/letter_template
 */
    signupCreateLetterTemplate: (body, params = {}) =>
      this.request({
        path: `/letter_template`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLoadOneLetterTemplate
     * @summary 載入單一信件模板
     * @request GET:/letter_template/{letterTemplateId}
     */
    signupLoadOneLetterTemplate: (letterTemplateId, params = {}) =>
      this.request({
        path: `/letter_template/${letterTemplateId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupDeleteLetterTemplate
     * @summary 刪除信件模板
     * @request DELETE:/letter_template/{letterTemplateId}
     */
    signupDeleteLetterTemplate: (letterTemplateId, body, params = {}) =>
      this.request({
        path: `/letter_template/${letterTemplateId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupUpdateLetterTemplate
     * @summary 編輯信件模板
     * @request PUT:/letter_template/{letterTemplateId}
     */
    signupUpdateLetterTemplate: (letterTemplateId, body, params = {}) =>
      this.request({
        path: `/letter_template/${letterTemplateId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  live = {
    /**
 * No description
 *
 * @tags live
 * @name LiveLiveList
 * @summary 直播管理
載入直播列表
 * @request GET:/live
 */
    liveLiveList: (query, params = {}) =>
      this.request({
        path: `/live`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCreateLive
     * @summary 新增直播
     * @request POST:/live
     */
    liveCreateLive: (body, params = {}) =>
      this.request({
        path: `/live`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveExportLiveUserExcel
     * @summary 匯出直播使用者Excel
     * @request POST:/live/export_live_user_excel
     */
    liveExportLiveUserExcel: (body, params = {}) =>
      this.request({
        path: `/live/export_live_user_excel`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveLoadSignupLiveList
     * @summary 檢視已報名直播列表
     * @request GET:/live/load_signup_live_list
     */
    liveLoadSignupLiveList: (query, params = {}) =>
      this.request({
        path: `/live/load_signup_live_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveReduceFirmLiveHours
     * @summary 扣除廠商直播時數
     * @request POST:/live/reduce_firm_live_hours
     */
    liveReduceFirmLiveHours: (body, params = {}) =>
      this.request({
        path: `/live/reduce_firm_live_hours`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateLiveUserMemo
     * @summary 編輯直播使用者備註
     * @request PUT:/live/update_live_user_memo
     */
    liveUpdateLiveUserMemo: (body, params = {}) =>
      this.request({
        path: `/live/update_live_user_memo`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateSignupUserData
     * @summary 編輯報名使用者資料
     * @request PUT:/live/update_signup_user_data
     */
    liveUpdateSignupUserData: (body, params = {}) =>
      this.request({
        path: `/live/update_signup_user_data`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateSignupUserIsCancelNotice
     * @summary 編輯報名使用者寄發通知信狀態
     * @request PUT:/live/update_signup_user_is_cancel_notice
     */
    liveUpdateSignupUserIsCancelNotice: (body, params = {}) =>
      this.request({
        path: `/live/update_signup_user_is_cancel_notice`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveLoadOneLive
     * @summary 獲取單一直播內容
     * @request GET:/live/{liveId}
     */
    liveLoadOneLive: (liveId, params = {}) =>
      this.request({
        path: `/live/${liveId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveDeleteLive
     * @summary 刪除直播
     * @request DELETE:/live/{liveId}
     */
    liveDeleteLive: (liveId, body, params = {}) =>
      this.request({
        path: `/live/${liveId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateLive
     * @summary 編輯直播
     * @request PUT:/live/{liveId}
     */
    liveUpdateLive: (liveId, body, params = {}) =>
      this.request({
        path: `/live/${liveId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveLoadLiveUserList
     * @summary 檢視直播使用者列表
     * @request GET:/live/{liveId}/load_live_user_list
     */
    liveLoadLiveUserList: (liveId, query, params = {}) =>
      this.request({
        path: `/live/${liveId}/load_live_user_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  loadAnnouncementList = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackLoadAnnouncementList
     * @summary 載入公告列表
     * @request GET:/load_announcement_list
     */
    cutrackLoadAnnouncementList: (params = {}) =>
      this.request({
        path: `/load_announcement_list`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  moveImg = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackMoveImg
     * @summary 搬移圖片
     * @request GET:/move_img
     */
    cutrackMoveImg: (query, params = {}) =>
      this.request({
        path: `/move_img`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  order = {
    /**
 * No description
 *
 * @tags Cutrack
 * @name CutrackOrderList
 * @summary 訂單管理
載入訂單列表
 * @request GET:/order
 */
    cutrackOrderList: (query, params = {}) =>
      this.request({
        path: `/order`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackCreateOrder
     * @summary 新增訂單
     * @request POST:/order
     */
    cutrackCreateOrder: (body, params = {}) =>
      this.request({
        path: `/order`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackLoadOneOrder
     * @summary 載入單一訂單內容
     * @request GET:/order/{orderId}
     */
    cutrackLoadOneOrder: (orderId, params = {}) =>
      this.request({
        path: `/order/${orderId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  partner = {
    /**
     * No description
     *
     * @tags association
     * @name AssociationPartnerList
     * @summary 夥伴列表
     * @request GET:/partner
     */
    associationPartnerList: (query, params = {}) =>
      this.request({
        path: `/partner`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationCreatePartner
     * @summary 建立夥伴
     * @request POST:/partner
     */
    associationCreatePartner: (body, params = {}) =>
      this.request({
        path: `/partner`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationFirmAssociationEffectList
     * @summary 聯盟成效列表
     * @request GET:/partner/association
     */
    associationFirmAssociationEffectList: (query, params = {}) =>
      this.request({
        path: `/partner/association`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationCreateAssociation
     * @summary 建立聯盟(綁定報名頁)
     * @request POST:/partner/association
     */
    associationCreateAssociation: (body, params = {}) =>
      this.request({
        path: `/partner/association`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationAssociationChangeUrlLetter
     * @summary 寄發聯盟修改網址通知信
     * @request POST:/partner/association/association_change_url_letter
     */
    associationAssociationChangeUrlLetter: (body, params = {}) =>
      this.request({
        path: `/partner/association/association_change_url_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationAssociationForgotPassLetter
     * @summary 寄發聯盟修改密碼通知信
     * @request POST:/partner/association/association_forgot_pass_letter
     */
    associationAssociationForgotPassLetter: (body, params = {}) =>
      this.request({
        path: `/partner/association/association_forgot_pass_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationAssociationNoticeLetter
     * @summary 寄發聯盟通知信
     * @request POST:/partner/association/association_notice_letter
     */
    associationAssociationNoticeLetter: (body, params = {}) =>
      this.request({
        path: `/partner/association/association_notice_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationFirmAssociationTimeSearch
     * @summary 廠商查詢聯盟時間區間
     * @request GET:/partner/association/firm_association_time_search
     */
    associationFirmAssociationTimeSearch: (query, params = {}) =>
      this.request({
        path: `/partner/association/firm_association_time_search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationFirmSignupEffectList
     * @summary 報名成效列表
     * @request GET:/partner/association/firm_signup_effect_list
     */
    associationFirmSignupEffectList: (query, params = {}) =>
      this.request({
        path: `/partner/association/firm_signup_effect_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationLoadOneAssociation
     * @summary 載入單一聯盟內容
     * @request GET:/partner/association/{associationId}
     */
    associationLoadOneAssociation: (associationId, params = {}) =>
      this.request({
        path: `/partner/association/${associationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationDeleteAssociation
     * @summary 刪除聯盟
     * @request DELETE:/partner/association/{associationId}
     */
    associationDeleteAssociation: (associationId, body, params = {}) =>
      this.request({
        path: `/partner/association/${associationId}`,
        method: 'DELETE',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationUpdateAssociation
     * @summary 編輯聯盟
     * @request PUT:/partner/association/{associationId}
     */
    associationUpdateAssociation: (associationId, body, params = {}) =>
      this.request({
        path: `/partner/association/${associationId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationPartnerAvailableSignupList
     * @summary 獲取夥伴可綁定報名列表
     * @request GET:/partner/partner_available_signup_list
     */
    associationPartnerAvailableSignupList: (query, params = {}) =>
      this.request({
        path: `/partner/partner_available_signup_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationLoadOnePartner
     * @summary 載入單一夥伴內容
     * @request GET:/partner/{partnerId}
     */
    associationLoadOnePartner: (partnerId, params = {}) =>
      this.request({
        path: `/partner/${partnerId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationDeletePartner
     * @summary 刪除夥伴
     * @request DELETE:/partner/{partnerId}
     */
    associationDeletePartner: (partnerId, body, params = {}) =>
      this.request({
        path: `/partner/${partnerId}`,
        method: 'DELETE',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags association
     * @name AssociationUpdatePartner
     * @summary 編輯夥伴
     * @request PUT:/partner/{partnerId}
     */
    associationUpdatePartner: (partnerId, body, params = {}) =>
      this.request({
        path: `/partner/${partnerId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  partnerAssociationEffectList = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackPartnerAssociationEffectList
     * @summary 夥伴檢視成效列表
     * @request GET:/partner_association_effect_list
     */
    cutrackPartnerAssociationEffectList: (query, params = {}) =>
      this.request({
        path: `/partner_association_effect_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  partnerAssociationTimeSearch = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackPartnerAssociationTimeSearch
     * @summary 夥伴查詢聯盟時間區間
     * @request GET:/partner_association_time_search
     */
    cutrackPartnerAssociationTimeSearch: (query, params = {}) =>
      this.request({
        path: `/partner_association_time_search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  product = {
    /**
 * No description
 *
 * @tags live
 * @name LiveProductList
 * @summary 商品管理
載入商品列表
 * @request GET:/product
 */
    liveProductList: (query, params = {}) =>
      this.request({
        path: `/product`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCreateProduct
     * @summary 新增商品
     * @request POST:/product
     */
    liveCreateProduct: (body, params = {}) =>
      this.request({
        path: `/product`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveDeleteProduct
     * @summary 刪除商品
     * @request DELETE:/product/{productId}
     */
    liveDeleteProduct: (productId, body, params = {}) =>
      this.request({
        path: `/product/${productId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateProduct
     * @summary 編輯商品
     * @request PUT:/product/{productId}
     */
    liveUpdateProduct: (productId, body, params = {}) =>
      this.request({
        path: `/product/${productId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  salesPage = {
    /**
 * No description
 *
 * @tags signup
 * @name SignupCreateSalesPage
 * @summary 銷售頁管理
建立銷售頁
 * @request POST:/sales_page
 */
    signupCreateSalesPage: (body, params = {}) =>
      this.request({
        path: `/sales_page`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLoadOneSalesPage
     * @summary 載入單一銷售頁內容
     * @request GET:/sales_page/{salesPageId}
     */
    signupLoadOneSalesPage: (salesPageId, params = {}) =>
      this.request({
        path: `/sales_page/${salesPageId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupDeleteSalesPage
     * @summary 刪除銷售頁
     * @request DELETE:/sales_page/{salesPageId}
     */
    signupDeleteSalesPage: (salesPageId, body, params = {}) =>
      this.request({
        path: `/sales_page/${salesPageId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupUpdateSalesPage
     * @summary 編輯銷售頁
     * @request PUT:/sales_page/{salesPageId}
     */
    signupUpdateSalesPage: (salesPageId, body, params = {}) =>
      this.request({
        path: `/sales_page/${salesPageId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  sendForgotPassLetter = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackSendForgotPassLetter
     * @summary 寄送忘記密碼信件
     * @request POST:/send_forgot_pass_letter
     */
    cutrackSendForgotPassLetter: (body, params = {}) =>
      this.request({
        path: `/send_forgot_pass_letter`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  signup = {
    /**
 * No description
 *
 * @tags signup
 * @name SignupSignupList
 * @summary 報名管理
載入報名列表
 * @request GET:/signup
 */
    signupSignupList: (query, params = {}) =>
      this.request({
        path: `/signup`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupCreateSignup
     * @summary 新增報名
     * @request POST:/signup
     */
    signupCreateSignup: (body, params = {}) =>
      this.request({
        path: `/signup`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupExportSignupUserExcel
     * @summary 匯出報名使用者Excel
     * @request POST:/signup/export_signup_user_excel
     */
    signupExportSignupUserExcel: (body, params = {}) =>
      this.request({
        path: `/signup/export_signup_user_excel`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupFirmCashFlowList
     * @summary 獲取廠商啟用金流列表
     * @request GET:/signup/firm_cash_flow_list
     */
    signupFirmCashFlowList: (query, params = {}) =>
      this.request({
        path: `/signup/firm_cash_flow_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupSearchOneDaySignupList
     * @summary 獲取單日報名使用者列表
     * @request GET:/signup/search_one_day_signup_list
     */
    signupSearchOneDaySignupList: (query, params = {}) =>
      this.request({
        path: `/signup/search_one_day_signup_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupSignupAvailableLiveList
     * @summary 獲取報名可使用的直播列表
     * @request GET:/signup/signup_available_live_list
     */
    signupSignupAvailableLiveList: (query, params = {}) =>
      this.request({
        path: `/signup/signup_available_live_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupLoadOneSignup
     * @summary 載入單一報名內容
     * @request GET:/signup/{signupId}
     */
    signupLoadOneSignup: (signupId, params = {}) =>
      this.request({
        path: `/signup/${signupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupDeleteSignup
     * @summary 刪除報名
     * @request DELETE:/signup/{signupId}
     */
    signupDeleteSignup: (signupId, body, params = {}) =>
      this.request({
        path: `/signup/${signupId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags signup
     * @name SignupUpdateSignup
     * @summary 編輯報名
     * @request PUT:/signup/{signupId}
     */
    signupUpdateSignup: (signupId, body, params = {}) =>
      this.request({
        path: `/signup/${signupId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  updateCasbinPolicy = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackUpdateCasbinPolicy
     * @summary 更新casbin策略
     * @request GET:/update_casbin_policy
     */
    cutrackUpdateCasbinPolicy: (params = {}) =>
      this.request({
        path: `/update_casbin_policy`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  updateForgotPass = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackUpdateForgotPass
     * @summary 忘記密碼_編輯新密碼
     * @request PUT:/update_forgot_pass
     */
    cutrackUpdateForgotPass: (body, params = {}) =>
      this.request({
        path: `/update_forgot_pass`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  urlContrastRedirect = {
    /**
     * No description
     *
     * @tags Cutrack
     * @name CutrackUrlContrastRedirect
     * @summary 網址對照跳轉
     * @request GET:/url_contrast_redirect
     */
    cutrackUrlContrastRedirect: (query, params = {}) =>
      this.request({
        path: `/url_contrast_redirect`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  video = {
    /**
     * No description
     *
     * @tags live
     * @name LiveVideoList
     * @summary 載入影片列表
     * @request GET:/video
     */
    liveVideoList: (query, params = {}) =>
      this.request({
        path: `/video`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
 * No description
 *
 * @tags live
 * @name LiveUploadFileChunk
 * @summary 影片管理
上傳影片
 * @request POST:/video
 */
    liveUploadFileChunk: (body, params = {}) =>
      this.request({
        path: `/video`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUploadImgTest
     * @summary 上傳圖片測試
     * @request POST:/video/upload_img_test
     */
    liveUploadImgTest: (body, params = {}) =>
      this.request({
        path: `/video/upload_img_test`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveDeleteVideo
     * @summary 刪除影片
     * @request DELETE:/video/{videoId}
     */
    liveDeleteVideo: (videoId, body, params = {}) =>
      this.request({
        path: `/video/${videoId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateVideo
     * @summary 編輯影片
     * @request PUT:/video/{videoId}
     */
    liveUpdateVideo: (videoId, body, params = {}) =>
      this.request({
        path: `/video/${videoId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  virtualUser = {
    /**
 * No description
 *
 * @tags live
 * @name LiveVirtualUserList
 * @summary 虛擬使用者
載入虛擬使用者列表
 * @request GET:/virtual_user
 */
    liveVirtualUserList: (query, params = {}) =>
      this.request({
        path: `/virtual_user`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveCreateVirtualUser
     * @summary 新增虛擬使用者
     * @request POST:/virtual_user
     */
    liveCreateVirtualUser: (body, params = {}) =>
      this.request({
        path: `/virtual_user`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUserImgList
     * @summary 使用者頭貼列表
     * @request GET:/virtual_user/user_img_list
     */
    liveUserImgList: (params = {}) =>
      this.request({
        path: `/virtual_user/user_img_list`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveDeleteVirtualUser
     * @summary 刪除虛擬使用者
     * @request DELETE:/virtual_user/{virtualUserId}
     */
    liveDeleteVirtualUser: (virtualUserId, body, params = {}) =>
      this.request({
        path: `/virtual_user/${virtualUserId}`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags live
     * @name LiveUpdateVirtualUser
     * @summary 編輯虛擬使用者
     * @request PUT:/virtual_user/{virtualUserId}
     */
    liveUpdateVirtualUser: (virtualUserId, body, params = {}) =>
      this.request({
        path: `/virtual_user/${virtualUserId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
