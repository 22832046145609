import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from 'redux/reducers'
// import Localization from 'localization'
import Router from 'router'
import * as serviceWorker from './serviceWorker'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import HttpsRedirect from 'react-https-redirect'
import './global.scss'
import { SWRConfig } from 'swr'
import toast from 'react-hot-toast'
import './i18n'

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const middlewares = [routeMiddleware]
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares, thunk)))
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <HttpsRedirect>
      {/* <Localization> */}
      <SWRConfig
        value={{
          focusThrottleInterval: 5000, // onBlur 5 秒後才會重新發送請求
          onError: (err, key, config) => {
            if (err.error.code === 2) {
              toast.error('權限不足，請聯繫您的帳號管理者')
            }
            if (err.error.code === 5) {
              // 報名頁找不到時 跳轉到404
              history.push('/client-404')
            }
            if (err.error.code === 13) {
              toast.error('查無資料')
            }
            // if (err.status === 503 || err.error.code === 14) {
            //   toast.error('系統發生錯誤，請嘗試重新整理頁面')
            // }
          },
          onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            if (error.status === 503 || error.error.code === 14) {
              revalidate()
            }
            // 當錯誤超過5次時 或 error.code 為 2 時(無權限)，不再重試
            if (error.error.code === 2) return
            if (error.error.code === 13) return
            if (retryCount >= 5) return
          },
          errorRetryInterval: 2000, // 每 2 秒後重試
          errorRetryCount: 5, // 最多重試 5 次
        }}
      >
        <Router history={history} />
      </SWRConfig>
      {/* </Localization> */}
    </HttpsRedirect>
  </Provider>,
)

serviceWorker.unregister()
export { store, history }
