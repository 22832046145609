/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export var ContentType
;(function (ContentType) {
  ContentType['Json'] = 'application/json'
  ContentType['FormData'] = 'multipart/form-data'
  ContentType['UrlEncoded'] = 'application/x-www-form-urlencoded'
  ContentType['Text'] = 'text/plain'
})(ContentType || (ContentType = {}))
export class HttpClient {
  baseUrl = ''
  securityData = null
  securityWorker
  abortControllers = new Map()
  customFetch = (...fetchParams) => fetch(...fetchParams)
  baseApiParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
  constructor(apiConfig = {}) {
    Object.assign(this, apiConfig)
  }
  setSecurityData = (data) => {
    this.securityData = data
  }
  encodeQueryParam(key, value) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }
  addQueryParam(query, key) {
    return this.encodeQueryParam(key, query[key])
  }
  addArrayQueryParam(query, key) {
    const value = query[key]
    return value.map((v) => this.encodeQueryParam(key, v)).join('&')
  }
  toQueryString(rawQuery) {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&')
  }
  addQueryParams(rawQuery) {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }
  contentFormatters = {
    [ContentType.Json]: (input) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input) => this.toQueryString(input),
  }
  mergeRequestParams(params1, params2) {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }
  createAbortSignal = (cancelToken) => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }
    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }
  abortRequest = (cancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)
    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }
  request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format
    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response
      r.data = null
      r.error = null
      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })
      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }
      if (!response.ok) throw data
      return data
    })
  }
}
/**
 * @title index.proto
 * @version version not set
 */
export class Api extends HttpClient {
  live = {
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetLiveInfo
     * @summary 取得直播資訊
     * @request GET:/live
     */
    chatGetLiveInfo: (query, params = {}) =>
      this.request({
        path: `/live`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatLeaveStream
     * @summary 離開聊天室
     * @request DELETE:/live
     */
    chatLeaveStream: (body, params = {}) =>
      this.request({
        path: `/live`,
        method: 'DELETE',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatCreateStream
     * @summary 加入聊天室
     * @request POST:/live
     */
    chatCreateStream: (body, params = {}) =>
      this.request({
        path: `/live`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetBlockList
     * @summary 取得黑名單列表
     * @request GET:/live/block_list
     */
    chatGetBlockList: (query, params = {}) =>
      this.request({
        path: `/live/block_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatBlockUser
     * @summary 列為黑名單
     * @request POST:/live/block_user
     */
    chatBlockUser: (body, params = {}) =>
      this.request({
        path: `/live/block_user`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatBroadcastMessage
     * @summary 推送訊息
     * @request POST:/live/broadcast
     */
    chatBroadcastMessage: (body, params = {}) =>
      this.request({
        path: `/live/broadcast`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatCloseRoom
     * @summary 關閉直播聊天室
     * @request DELETE:/live/close
     */
    chatCloseRoom: (query, params = {}) =>
      this.request({
        path: `/live/close`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetLiveComment
     * @summary 取得直播全部假留言
     * @request GET:/live/comment
     */
    chatGetLiveComment: (query, params = {}) =>
      this.request({
        path: `/live/comment`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetLiveUserInfo
     * @summary 直播點擊取得個人資訊
     * @request GET:/live/comment/user
     */
    chatGetLiveUserInfo: (query, params = {}) =>
      this.request({
        path: `/live/comment/user`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetHistoryMessage
     * @summary 聊天室歷史訊息
     * @request GET:/live/history_message
     */
    chatGetHistoryMessage: (query, params = {}) =>
      this.request({
        path: `/live/history_message`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatSetBanKeyword
     * @summary 禁止關鍵字
     * @request POST:/live/keyword
     */
    chatSetBanKeyword: (body, params = {}) =>
      this.request({
        path: `/live/keyword`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatMarkComment
     * @summary 標注留言
     * @request POST:/live/mark
     */
    chatMarkComment: (body, params = {}) =>
      this.request({
        path: `/live/mark`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetUser
     * @summary 取得用戶資訊
     * @request GET:/live/user
     */
    chatGetUser: (query, params = {}) =>
      this.request({
        path: `/live/user`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatCreateUser
     * @summary 用戶報名
     * @request POST:/live/user
     */
    chatCreateUser: (body, params = {}) =>
      this.request({
        path: `/live/user`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatSetUserTimeLog
     * @summary 紀錄用戶進入&離開直播時間
     * @request POST:/live/user/time_log
     */
    chatSetUserTimeLog: (body, params = {}) =>
      this.request({
        path: `/live/user/time_log`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatUserImgList
     * @summary 使用者頭貼列表
     * @request GET:/live/user_img_list
     */
    chatUserImgList: (params = {}) =>
      this.request({
        path: `/live/user_img_list`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatLiveViewContent
     * @summary 直播ViewContent
     * @request POST:/live/view_content
     */
    chatLiveViewContent: (body, params = {}) =>
      this.request({
        path: `/live/view_content`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  salesPage = {
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetOneSalesPageInfo
     * @summary 載入一頁式購物
     * @request GET:/sales_page
     */
    chatGetOneSalesPageInfo: (query, params = {}) =>
      this.request({
        path: `/sales_page`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  signup = {
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetSignupInfo
     * @summary 取得報名資訊
     * @request GET:/signup
     */
    chatGetSignupInfo: (query, params = {}) =>
      this.request({
        path: `/signup`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatBatchSignUp
     * @summary 批次報名
     * @request POST:/signup/batch
     */
    chatBatchSignUp: (body, params = {}) =>
      this.request({
        path: `/signup/batch`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatGetContrastInfo
     * @summary 取得報名對照資料
     * @request GET:/signup/contrast
     */
    chatGetContrastInfo: (query, params = {}) =>
      this.request({
        path: `/signup/contrast`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatScrollTrack
     * @summary 滑動追蹤
     * @request POST:/signup/scroll_track
     */
    chatScrollTrack: (body, params = {}) =>
      this.request({
        path: `/signup/scroll_track`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Chat
     * @name ChatSignupViewContent
     * @summary 報名ViewContent
     * @request POST:/signup/view_content
     */
    chatSignupViewContent: (body, params = {}) =>
      this.request({
        path: `/signup/view_content`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
