import toast from 'react-hot-toast'
import { teddyApi } from './base.ts'

export const getVideos = async (firmId) => {
  const { data } = await teddyApi.video.liveVideoList({
    firmId: firmId,
  })
  return data
}

export const deleteVideo = async (videoId, firmId) => {
  const { data } = await teddyApi.video.liveDeleteVideo(videoId, { firmId: firmId })
  toast.remove('loadingToast')
  return data
}
export const updateVideo = async (productId, req) => {
  const { data } = await teddyApi.video.liveUpdateVideo(productId, req)
  toast.remove('loadingToast')
  return data
}

export const uploadVideo = async (req) => {
  const { data } = await teddyApi.video.liveUploadFileChunk(req)
  return data
}

export const uploadImgTest = async (productId, firmId) => {
  const { data } = await teddyApi.video.liveUploadImgTest(productId, {
    firmId: firmId,
  })
  toast.remove('loadingToast')
  return data
}
