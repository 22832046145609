const initialState = {
  //
  firmName: null,
  //廠商圖片
  firmImage: [],
  //廠商圖片副檔名
  firmExtension: null,
  //FB Pixel ID
  pixelId: null,
  //FB 轉換API
  conversionApi: null,
  //電子豹 API KEY
  surenotifyApiKey: null,
  //寄出信件信箱
  surenotifySendEmail: null,
  //圖片連結
  firmImgUrl: null,
  //電子豹啟用狀態
  emailApiStatus: false,
  //直播開始前X分鐘發送通知
  // sendNoticeLetterTime: 1,
  // 聯繫網址
  contactLink: '',
  //google Tag Manager
  gtmId: '',
  //token
  token: null,
  //loaded
  loaded: false,
  /** tiktok pixel */
  tiktokPixelId: '',
  /** tiktok access token */
  tiktokAccessToken: '',

  bombmyAuthority: {
    bombmyLive: 0,
    bombmySignup: 0,
    bombmySetting: 0,
  },
  platformAuthority: {
    bombmy: false,
    bombmyAssociation: false,
    bombcourse: false,
  },

  firmInfo: {
    firmId: '',
    name: '',
    imgUrl: '',
    liveHours: 0,
    bombmyDomainName: '',
  },
  userInfo: {
    userId: '',
    userName: '',
    userPhone: '',
    userEmail: '',
    oneDeviceStatus: false,
    token: '',
    authorizationToken: '',
  },
  deviceId: '',
  csrf: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'FIRM_LOGIN': {
      state.firmInfo = action.payload.firmInfo
      state.userInfo = action.payload.userInfo
      state.bombmyAuthority = action.payload.bombmyAuthority
      state.platformAuthority = action.payload.platformAuthority
      state.deviceId = action.payload.deviceId
      state.csrf = action.payload.csrf

      const storageData = {
        token: action.payload.userInfo.token,
        firmId: action.payload.firmInfo.firmId,
        userId: action.payload.userInfo.userId,
        deviceId: action.payload.deviceId,
        csrf: action.payload.csrf,
      }

      localStorage.setItem('ramate_auth', JSON.stringify(storageData))
      return { ...state }
    }

    case 'FIRM_LOGOUT': {
      localStorage.clear()
      state = initialState
      location.reload()
      return { ...state }
    }

    case 'FIRM_SETTING_SET_IMAGE': {
      state.firmImage = action.payload.image
      state.firmExtension = '.png'
      state.firmInfo.imgUrl = action.payload.imgUrl
      return { ...state }
    }

    case 'FIRM_SETTING_DEL_IMAGE': {
      state.firmImage = []
      state.firmInfo.imgUrl = null
      return { ...state }
    }

    case 'FIRM_SETTING_CHANGE_EMAIL_API_STATUS': {
      state.emailApiStatus = !state.emailApiStatus
      return { ...state }
    }

    case 'FIRM_LOAD_DATA': {
      state.loaded = true
      return { ...state, ...action.payload }
    }

    default:
      return state
  }
}
