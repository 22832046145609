/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export var ContentType
;(function (ContentType) {
  ContentType['Json'] = 'application/json'
  ContentType['FormData'] = 'multipart/form-data'
  ContentType['UrlEncoded'] = 'application/x-www-form-urlencoded'
  ContentType['Text'] = 'text/plain'
})(ContentType || (ContentType = {}))
export class HttpClient {
  baseUrl = ''
  securityData = null
  securityWorker
  abortControllers = new Map()
  customFetch = (...fetchParams) => fetch(...fetchParams)
  baseApiParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
  constructor(apiConfig = {}) {
    Object.assign(this, apiConfig)
  }
  setSecurityData = (data) => {
    this.securityData = data
  }
  encodeQueryParam(key, value) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }
  addQueryParam(query, key) {
    return this.encodeQueryParam(key, query[key])
  }
  addArrayQueryParam(query, key) {
    const value = query[key]
    return value.map((v) => this.encodeQueryParam(key, v)).join('&')
  }
  toQueryString(rawQuery) {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&')
  }
  addQueryParams(rawQuery) {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }
  contentFormatters = {
    [ContentType.Json]: (input) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input) => this.toQueryString(input),
  }
  mergeRequestParams(params1, params2) {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }
  createAbortSignal = (cancelToken) => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }
    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }
  abortRequest = (cancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)
    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }
  request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format
    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response
      r.data = null
      r.error = null
      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })
      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }
      if (!response.ok) throw data
      return data
    })
  }
}
/**
 * @title index_purchase.proto
 * @version version not set
 */
export class Api extends HttpClient {
  addPointValue = {
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseAddPointValue
     * @summary 儲值簡訊點數
     * @request POST:/add_point_value
     */
    purchaseAddPointValue: (body, params = {}) =>
      this.request({
        path: `/add_point_value`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  addValue = {
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseAddValue
     * @summary 儲值幫賣
     * @request POST:/add_value
     */
    purchaseAddValue: (body, params = {}) =>
      this.request({
        path: `/add_value`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  checkout = {
    /**
     * No description
     *
     * @tags CashFlow
     * @name CashFlowPayNowSdkCheckout
     * @summary checkout
     * @request POST:/checkout
     */
    cashFlowPayNowSdkCheckout: (body, params = {}) =>
      this.request({
        path: `/checkout`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  invoiceInfo = {
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderUpdateInvoiceInfo
     * @summary 訂單更改發票資訊
     * @request PUT:/invoice_info
     */
    signupOrderSignupOrderUpdateInvoiceInfo: (body, params = {}) =>
      this.request({
        path: `/invoice_info`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  paynow = {
    /**
     * No description
     *
     * @tags CashFlow
     * @name CashFlowPayNowSdkPayment
     * @summary paynow sdk付款頁面
     * @request GET:/paynow/{paymentId}/payment
     */
    cashFlowPayNowSdkPayment: (paymentId, params = {}) =>
      this.request({
        path: `/paynow/${paymentId}/payment`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  purchase = {
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseAtmPaidNotify
     * @summary ATM通知付款
     * @request POST:/purchase/atm_paid_notify
     */
    purchaseAtmPaidNotify: (body, params = {}) =>
      this.request({
        path: `/purchase/atm_paid_notify`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseCheckNotice
     * @summary 確認須知
     * @request POST:/purchase/check_notice
     */
    purchaseCheckNotice: (body, params = {}) =>
      this.request({
        path: `/purchase/check_notice`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseCreateOrder
     * @summary 建立訂單
     * @request POST:/purchase/create_order
     */
    purchaseCreateOrder: (body, params = {}) =>
      this.request({
        path: `/purchase/create_order`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseCreatePayment
     * @summary 建立付款
     * @request POST:/purchase/create_payment
     */
    purchaseCreatePayment: (body, params = {}) =>
      this.request({
        path: `/purchase/create_payment`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseCreateOrderLogCustomer
     * @summary 客戶新增訂單記錄
     * @request POST:/purchase/customer_message
     */
    purchaseCreateOrderLogCustomer: (body, params = {}) =>
      this.request({
        path: `/purchase/customer_message`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseGetFirmDeliveryMethod
     * @summary 取得運送方式
     * @request GET:/purchase/delivery_method
     */
    purchaseGetFirmDeliveryMethod: (query, params = {}) =>
      this.request({
        path: `/purchase/delivery_method`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CashFlow
     * @name CashFlowGetFirmInvoiceMethod
     * @summary 取得開立發票方式
     * @request GET:/purchase/firm_invoice_method
     */
    cashFlowGetFirmInvoiceMethod: (query, params = {}) =>
      this.request({
        path: `/purchase/firm_invoice_method`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseGetFirmPayMethod
     * @summary 取得付款方式
     * @request GET:/purchase/firm_pay_method
     */
    purchaseGetFirmPayMethod: (query, params = {}) =>
      this.request({
        path: `/purchase/firm_pay_method`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseUpdateInvoiceInfo
     * @summary 更改發票資訊
     * @request PUT:/purchase/invoice_info
     */
    purchaseUpdateInvoiceInfo: (body, params = {}) =>
      this.request({
        path: `/purchase/invoice_info`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CRM
     * @name CrmUpdateInvoiceInfoBackend
     * @summary 更改發票資訊
     * @request PUT:/purchase/invoice_info_backend
     */
    crmUpdateInvoiceInfoBackend: (body, params = {}) =>
      this.request({
        path: `/purchase/invoice_info_backend`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CashFlow
     * @name CashFlowInvoiceNotify
     * @summary 發送發票通知
     * @request POST:/purchase/invoice_notify
     */
    cashFlowInvoiceNotify: (body, params = {}) =>
      this.request({
        path: `/purchase/invoice_notify`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseDeleteOrderInvoiceScheduler
     * @summary 刪除訂單發票排程（後端）
     * @request DELETE:/purchase/invoice_scheduler
     */
    purchaseDeleteOrderInvoiceScheduler: (query, params = {}) =>
      this.request({
        path: `/purchase/invoice_scheduler`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseSetOrderInvoiceScheduler
     * @summary 設定訂單發票排程（後端）
     * @request POST:/purchase/invoice_scheduler
     */
    purchaseSetOrderInvoiceScheduler: (body, params = {}) =>
      this.request({
        path: `/purchase/invoice_scheduler`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseUpdateOrderInvoiceScheduler
     * @summary 更新訂單發票排程（後端）
     * @request PUT:/purchase/invoice_scheduler
     */
    purchaseUpdateOrderInvoiceScheduler: (body, params = {}) =>
      this.request({
        path: `/purchase/invoice_scheduler`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CRM
     * @name CrmIssueInvoice
     * @summary 開立發票
     * @request POST:/purchase/issue_invoice
     */
    crmIssueInvoice: (body, params = {}) =>
      this.request({
        path: `/purchase/issue_invoice`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CRM
     * @name CrmIssueInvoiceBackend
     * @summary 後台開立發票
     * @request POST:/purchase/issue_invoice_backend
     */
    crmIssueInvoiceBackend: (body, params = {}) =>
      this.request({
        path: `/purchase/issue_invoice_backend`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CashFlow
     * @name CashFlowGetLoveCodeList
     * @summary 取得捐贈碼列表
     * @request GET:/purchase/lovecode_list
     */
    cashFlowGetLoveCodeList: (query, params = {}) =>
      this.request({
        path: `/purchase/lovecode_list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseGetOrderInfo
     * @summary 取得訂單明細
     * @request GET:/purchase/order_info
     */
    purchaseGetOrderInfo: (query, params = {}) =>
      this.request({
        path: `/purchase/order_info`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CRM
     * @name CrmRefundPayment
     * @summary 退款
     * @request POST:/purchase/refund_payment
     */
    crmRefundPayment: (body, params = {}) =>
      this.request({
        path: `/purchase/refund_payment`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CRM
     * @name CrmReIssueInvoice
     * @summary 重開發票
     * @request POST:/purchase/reissue_invoice
     */
    crmReIssueInvoice: (body, params = {}) =>
      this.request({
        path: `/purchase/reissue_invoice`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseUpdateDeliveryMethod
     * @summary 更改運送方式
     * @request PUT:/purchase/update_delivery_method
     */
    purchaseUpdateDeliveryMethod: (body, params = {}) =>
      this.request({
        path: `/purchase/update_delivery_method`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseUpdatePaymentMethod
     * @summary 更改付款方式
     * @request PUT:/purchase/update_payment_method
     */
    purchaseUpdatePaymentMethod: (body, params = {}) =>
      this.request({
        path: `/purchase/update_payment_method`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CRM
     * @name CrmValidInvoice
     * @summary 作廢發票
     * @request POST:/purchase/valid_invoice
     */
    crmValidInvoice: (body, params = {}) =>
      this.request({
        path: `/purchase/valid_invoice`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  scheduler = {
    /**
     * No description
     *
     * @tags CashFlow
     * @name CashFlowIssueInvoiceScheduler
     * @summary 排程開立發票用(後端)
     * @request POST:/scheduler/issue_invoice
     */
    cashFlowIssueInvoiceScheduler: (body, params = {}) =>
      this.request({
        path: `/scheduler/issue_invoice`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  signupOrder = {
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderGetSignupOrderInfo
     * @summary 取得報名訂單
     * @request GET:/signup_order
     */
    signupOrderGetSignupOrderInfo: (query, params = {}) =>
      this.request({
        path: `/signup_order`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderCreateSignupOrder
     * @summary 用戶報名
     * @request POST:/signup_order
     */
    signupOrderCreateSignupOrder: (body, params = {}) =>
      this.request({
        path: `/signup_order`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderCreatePayment
     * @summary 報名建立付款
     * @request POST:/signup_order/create_payment
     */
    signupOrderSignupOrderCreatePayment: (body, params = {}) =>
      this.request({
        path: `/signup_order/create_payment`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderIssueInvoice
     * @summary 報名開立發票
     * @request POST:/signup_order/issue_invoice
     */
    signupOrderSignupOrderIssueInvoice: (body, params = {}) =>
      this.request({
        path: `/signup_order/issue_invoice`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderIssueInvoiceBackend
     * @summary 報名後台開立發票
     * @request POST:/signup_order/issue_invoice_backend
     */
    signupOrderSignupOrderIssueInvoiceBackend: (body, params = {}) =>
      this.request({
        path: `/signup_order/issue_invoice_backend`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderUpdateSignupOrderPaymentMethod
     * @summary 更改報名訂單付款方式
     * @request POST:/signup_order/payment_method
     */
    signupOrderUpdateSignupOrderPaymentMethod: (body, params = {}) =>
      this.request({
        path: `/signup_order/payment_method`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderRefundPayment
     * @summary 報名報名退款
     * @request POST:/signup_order/refund_payment
     */
    signupOrderSignupOrderRefundPayment: (body, params = {}) =>
      this.request({
        path: `/signup_order/refund_payment`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderReIssueInvoice
     * @summary 報名重開發票
     * @request POST:/signup_order/reissue_invoice
     */
    signupOrderSignupOrderReIssueInvoice: (body, params = {}) =>
      this.request({
        path: `/signup_order/reissue_invoice`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SignupOrder
     * @name SignupOrderSignupOrderValidInvoice
     * @summary 報名作廢發票
     * @request POST:/signup_order/valid_invoice
     */
    signupOrderSignupOrderValidInvoice: (body, params = {}) =>
      this.request({
        path: `/signup_order/valid_invoice`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  updateCasbinPolicy = {
    /**
     * No description
     *
     * @tags Purchase
     * @name PurchaseUpdateCasbinPolicy
     * @summary 更新casbin策略
     * @request GET:/update_casbin_policy
     */
    purchaseUpdateCasbinPolicy: (params = {}) =>
      this.request({
        path: `/update_casbin_policy`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
